import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Button,
  message,
  Spin,
  Row,
  Col,
  Avatar,
  Space,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { getUserById, updateUser } from "../../utils/user";
import { branchOptions, branchSuffix } from "../../constants/branch";
import { useRecoilValue } from "recoil";
import { userInfoState } from "../../atom/userInfo";

const { Option } = Select;

const UserEditPopup = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<any>(null);
  const userInfo = useRecoilValue(userInfoState);


  useEffect(() => {
    if(!userInfo.isAdmin && !userInfo.isPointAdmin) {
      if(process.env.NODE_ENV !== "development") 
        window.close();
    }
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    if (id) {
      fetchUser(id);
    }
  }, []);

  const fetchUser = async (id: string) => {
    try {
      const fetchedUser = await getUserById(id);
      if (fetchedUser) {
        setUserData(fetchedUser);
      }
    } catch (error) {
      console.error("사용자 정보 조회에 실패했습니다:", error);
      message.error("사용자 정보 조회에 실패했습니다.");
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const updatedUser = {
        ...userData,
        // score: userData.branch !== userData.prevBranch?.branch ? 0 : userData.score,
        prevBranch:
          userData.branch !== userData.prevBranch?.branch
            ? {
                branch: userData.prevBranch?.branch,
                score: userData.prevBranch?.score,
              }
            : userData.prevBranch || {},
      };

      console.log(updatedUser);
      const result = await updateUser(updatedUser, userInfo, true);
      if (result.success) {
        message.success("사용자 정보가 업데이트되었습니다.");
        window.opener.postMessage(
          { type: "USER_UPDATED", user: updatedUser },
          "*"
        );
        // window.close();
      } else {
        throw new Error("업데이트 실패");
      }
    } catch (error) {
      console.error("사용자 정보 업데이트 중 오류가 발생했습니다:", error);
      message.error("사용자 정보 업데이트에 실패했습니다.");
    }
    setLoading(false);
  };

  const canEdit = (field: string) => {

    if(userData.isRootAdmin && !userInfo.isRootAdmin) return false;

    
    if (userInfo.isAdmin && userInfo.isRootAdmin) {
      return true;
    }
    if (userInfo.isAdmin && !userInfo.isRootAdmin) {
      return userData?.branch === userInfo.branch;
    }
    return false;
  };

  const handleInputChange = (field: string, value: any) => {
    // setUserData((prevData: any) => ({
    //   ...prevData,
    //   [field]: value,
    // }));
    if (field === "isAdmin" && value === 3) {
      setUserData((prevData: any) => ({
        ...prevData,
        isPointAdmin: true,
        isAdmin: false,
      }));
    } else if (field === "isAdmin" && value !== 3) {
      setUserData((prevData: any) => ({
        ...prevData,
        isPointAdmin: false,
        isAdmin: value,
      }));
    } else {
      setUserData((prevData: any) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handlePhoneChange = (index: number, value: string) => {
    const phoneparts = userData?.phone
      ? userData.phone.split("-")
      : ["", "", ""];
    phoneparts[index] = value;
    handleInputChange("phone", phoneparts.join("-"));
  };

  const handleBranchPointChange = (branch: string, value: number) => {
    setUserData((prevData: any) => ({
      ...prevData,
      point: {
        ...prevData.point,
        [branch]: value,
      },
    }));
  };

  const handleBranchScoreChange = (branch: string, value: number) => {
    setUserData((prevData: any) => ({
      ...prevData,
      score: {
        ...prevData.score,
        [branch]: value,
      },
    }));
  };

  console.log(userData);
  if (loading) {
    return <Spin size="large" style={{ margin: "auto" }} />;
  }

  return (
    <div style={{ padding: "20px" }}>
      <h2>사용자 정보 수정</h2>
      <Form layout="vertical">
        <Row gutter={16} justify="center" style={{ marginBottom: 24 }}>
          <Col>
            <Avatar
              size={100}
              icon={<UserOutlined />}
              src={
                userData?.profileImage
                  ? `${process.env.REACT_APP_S3_STORAGE}/${
                      userData.profileImage
                    }?t=${new Date()}`
                  : ""
              }
              style={{ border: "2px solid #ffd700" }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="이름" required>
              <Input
                value={userData?.username}
                onChange={(e) => handleInputChange("username", e.target.value)}
                disabled={!canEdit("username")}
              />
            </Form.Item>
          </Col>
          {
            <Col span={8}>
              <Form.Item label="권한" required>
                <Select
                  defaultValue={userData?.isPointAdmin ? 3 : userData?.isAdmin ? true : false}
                  onChange={(value) => handleInputChange("isAdmin", value)}
                  disabled={((!userInfo.isAdmin || userInfo.branch !==  userData.branch) && !userInfo.isRootAdmin) || (userData.isRootAdmin && !userInfo.isRootAdmin)}
                >
                  <Option value={false}>일반사용자</Option>
                  {userInfo.isRootAdmin && <Option value={true}>지점관리자</Option>}
                  <Option value={3}>포인트관리자</Option>
                </Select>
              </Form.Item>
            </Col>
          }
          <Col span={8}>
            <Form.Item label="닉네임" required>
              <Input
                value={userData?.nickname}
                onChange={(e) => handleInputChange("nickname", e.target.value)}
                disabled={!canEdit("nickname")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="기본 지점" required>
              <Select
                value={userData?.branch}
                onChange={(value) => handleInputChange("branch", value)}
                disabled={!userInfo.isRootAdmin}
              >
                {branchOptions.map((branch) => (
                  <Option key={branch.label} value={branch.label}>
                    {branch.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="전화번호">
              <Space.Compact>
                <Input
                  style={{ width: "33%" }}
                  value={userData?.phone ? userData.phone.split("-")[0] : ""}
                  onChange={(e) => handlePhoneChange(0, e.target.value)}
                  maxLength={3}
                  disabled={!canEdit("phone")}
                  placeholder="010"
                />
                <Input
                  style={{ width: "33%" }}
                  value={userData?.phone ? userData.phone.split("-")[1] : ""}
                  onChange={(e) => handlePhoneChange(1, e.target.value)}
                  maxLength={4}
                  disabled={!canEdit("phone")}
                  placeholder="1234"
                />
                <Input
                  style={{ width: "33%" }}
                  value={userData?.phone ? userData.phone.split("-")[2] : ""}
                  onChange={(e) => handlePhoneChange(2, e.target.value)}
                  maxLength={4}
                  disabled={!canEdit("phone")}
                  placeholder="5678"
                />
              </Space.Compact>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Col span={8}>
            <Form.Item label="랭킹점수" required>
              <InputNumber
                style={{ width: "100%" }}
                value={userData?.score}
                onChange={(value) => handleInputChange("score", value)}
                disabled={!canEdit("score")}
              />
            </Form.Item>
            {userData?.prevBranch?.branch && (
              <div style={{ fontSize: 13, color: "#777", marginBottom: 20 }}>
                <div>이전 지점 : {userData.prevBranch.branch}</div>
                <div>이전 점수 : {userData.prevBranch.score}</div>
              </div>
            )}
          </Col> */}

          <Col span={8}>
            <Form.Item label="통합포인트" required>
              <InputNumber
                style={{ width: "100%" }}
                value={userData?.coin}
                onChange={(value) => handleInputChange("coin", value)}
                disabled={!userInfo.isOwner}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="자료실 접근 권한" valuePropName="checked">
              <Switch
                checked={userData?.dataAccessibility}
                onChange={(value) =>
                  handleInputChange("dataAccessibility", value)
                }
                disabled={!canEdit("dataAccessibility")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="가입 승인 여부" valuePropName="checked">
              <Switch
                checked={userData?.isApproved}
                onChange={(value) => handleInputChange("isApproved", value)}
                disabled={!canEdit("isApproved")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="지점별 랭킹 점수" required>
          <Row gutter={[16, 16]}>
            {branchOptions.map((branch: any) => (
              <Col span={8} key={branch.label}>
                <Form.Item label={branch.label} style={{ marginBottom: 0 }}>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={userData?.score?.[branch.label] || 0}
                    onChange={(value) =>
                      handleBranchScoreChange(branch.label, value as number)
                    }
                    disabled={
                      !(
                        userInfo.isRootAdmin ||
                        (
                          userInfo.branch === branch.label)
                      )
                    }
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form.Item>
        <Form.Item label="지점별 포인트" required>
          <Row gutter={[16, 16]}>
            {branchOptions.map((branch: any) => (
              <Col span={8} key={branch.label}>
                <Form.Item label={branch.label} style={{ marginBottom: 0 }}>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={userData?.point?.[branch.label] || 0}
                    onChange={(value) =>
                      handleBranchPointChange(branch.label, value as number)
                    }
                    suffix={branch.value}
                    disabled={
                      !(
                        userInfo.isRootAdmin ||
                        (
                          userInfo.branch === branch.label)
                      )
                    }
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleSubmit}
            // disabled={!(userInfo.isPointAdmin || userInfo.isAdmin)}
          >
            수정하기
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserEditPopup;
