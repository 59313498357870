import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { DynamoDBDocumentClient, GetCommand } from "@aws-sdk/lib-dynamodb";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";

// Lambda 클라이언트 설정
const lambdaClient = new LambdaClient({
  region: "ap-northeast-2", // 예: "us-east-1"
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || "",
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY || "",
  },
});

const client = new DynamoDBClient({
  region: "ap-northeast-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
  },
});

const docClient = DynamoDBDocumentClient.from(client);

export default function FindPassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const getCommand = new GetCommand({
        TableName: "user",
        Key: {
          id: values.email,
        },
      });
      const { Item } = await docClient.send(getCommand);
      if (!Item) {
        message.error("존재하지 않는 이메일입니다.");
        return;
      }

      const params: any = {
        FunctionName: "send-email", // Lambda 함수 이름
        InvocationType: "RequestResponse",
        Payload: JSON.stringify({ email: values.email }),
      };

      const command = new InvokeCommand(params);
      const response = await lambdaClient.send(command);

      // Lambda 응답 처리
      const responsePayload = JSON.parse(
        new TextDecoder().decode(response.Payload)
      );

      if (response.StatusCode === 200 && responsePayload.statusCode === 200) {
        message.success("비밀번호 재설정 링크가 이메일로 전송되었습니다.");
        form.resetFields();
      } else {
        throw new Error(responsePayload.body || "서버 오류");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(
        "비밀번호 재설정 링크 전송에 실패했습니다. 다시 시도해주세요."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 300, margin: "0 auto" }}>
      <h2>비밀번호 찾기</h2>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "이메일을 입력해주세요" },
            { type: "email", message: "유효한 이메일 주소를 입력해주세요" },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="이메일" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            비밀번호 재설정 링크 보내기
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
